import * as React from 'react';
import links from '@constants/links';
import overviewImg from './Overview.jpg';
import Container from '../../Container';
import Typography from '../../Typography';
import Button from '../../Button';
import './LearnMore.scss';

const LearnMore: React.FC = () => (
  <Container className="TDB-DataTypes__LearnMore">
    <Typography
      className="TDB-DataTypes__LearnMore__title mb-8"
      fontSize="heading-1"
      color="neutral-800"
      as="h2"
      align="center"
      fontWeight="bold"
    >
      Want to learn more about TileDB Cloud?
    </Typography>
    <div className="TDB-DataTypes__LearnMore__btn-container">
      <Button primary className="TDB-DataTypes__LearnMore__btn" to={`${links.cloud}`}>
        Capabilities
      </Button>
      <Button outlined className="TDB-DataTypes__LearnMore__btn" to={links.requestDemo}>
        Schedule a demo
      </Button>
    </div>
    <div className="TDB-DataTypes__LearnMore__img-container">
      <img src={overviewImg} className="TDB-DataTypes__LearnMore__img" alt="organizations" />
    </div>
  </Container>
);

export default LearnMore;
