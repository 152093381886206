import './Related.scss';
import React, { useMemo } from 'react';
import { dataTypes, industries, IndustryOrDataType } from '@constants/industriesAndDatatypes';
import LeftDecorator from './assets/decorator-left.svg';
import RightDecorator from './assets/decorator-right.svg';
import Typography from '../../Typography';
import RelatedItem, { RelatedItemPlaceholder } from './RelatedItem';
import Container from '../../Container';

interface RelatedProps {
  items: IndustryOrDataType[];
}

const dataTypeItems = Object.values(dataTypes);
const industryItems = Object.values(industries);

const Related: React.FC<RelatedProps> = (props) => {
  const { items = [] } = props;

  // Create the title depending on what items are passed
  const title = useMemo(() => {
    let hasDataType = false;
    let hasIndustry = false;

    items.forEach((item) => {
      if (!hasDataType) {
        hasDataType = dataTypeItems.some((i: any) => i.title === item.title);
      }
      if (!hasIndustry) {
        hasIndustry = industryItems.some((i: any) => i.title === item.title);
      }
    });

    if (hasDataType && hasIndustry) {
      return 'Related industries & data types';
    }

    return hasDataType ? 'Related data types' : 'Related industries';
  }, [items]);

  return (
    <div className="TDB-DataTypes__Related">
      <div className="TDB-DataTypes__Related__Decorators">
        <img src={LeftDecorator} alt="" />
        <img src={RightDecorator} alt="" />
        <Container>
          <Typography
            as="p"
            fontSize="heading-1"
            fontWeight="bold"
            color="neutral-800"
            align="center"
            className="TDB-DataTypes__Related__Title"
          >
            {title}
          </Typography>
        </Container>
      </div>
      <Container className="TDB-DataTypes__Related__Items">
        {items.map((item: IndustryOrDataType) => (
          <RelatedItem key={item.title} item={item} />
        ))}
        {/*
         * Add placeholder if the item length is odd
         */}
        {items.length % 2 !== 0 && <RelatedItemPlaceholder />}
      </Container>
    </div>
  );
};

export default Related;
