import React from 'react';
import IndustryDataTypeTemplate from '@components/IndustryDataType/IndustryDataTypeTemplate';
import { dataTypes, industries } from '@constants/industriesAndDatatypes';
import BackgroundImage from './assets/Video_Cover.jpg';
import metaImage from './assets/meta.jpg';

const VideoPage: React.FC = () => {
  return (
    <IndustryDataTypeTemplate
      pageName="data-type-video"
      helmet={{
        title: 'TileDB - Slice video segments without downloading huge files',
        description:
          'TileDB provides fast programmatic access to video data as 3D arrays that interoperate with NumPy, scikit-image, and other tools in your ML workflows.',
        shareImage: {
          url: metaImage,
          width: 1200,
          height: 627,
        },
      }}
      header="Video data"
      title="Minimize video storage costs and train ML models faster, directly from the cloud"
      heroImage={BackgroundImage}
      icon={dataTypes.video.icon}
      overview={
        <>
          <p>
            Video data is enormous, even when compressed, and is not analysis-ready. Metadata is not directly exposed, and data scientists
            still need to download huge files in order to train change detection models and run machine learning workflows.
          </p>
          <p>
            Jump right into large-scale video analytics using open-source TileDB arrays. TileDB lets you combine multiple compression
            filters and persist video data as multi-dimensional arrays on cloud object storage in an analysis-ready format. With TileDB
            Cloud, you can use integrations with popular data science tools, along with an inexpensive serverless compute platform, to
            manage your video data and train ML models faster. Enjoy extreme performance, secure governance and massive TCO savings by
            moving your video datasets and workflows to TileDB Cloud today.
          </p>
        </>
      }
      relatedItems={[industries.defense]}
    />
  );
};

export default VideoPage;
