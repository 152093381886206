import React from 'react';
import { IndustryOrDataType } from '@constants/industriesAndDatatypes';
import IndexLayout from '../../../layouts';
import Footer from '../../Footer';
import Header from '../../Header';
import Hero from '../Hero';
import LearnMore from '../LearnMore';
import Related from '../Related';

interface IndustryDataTypeTemplateProps {
  pageName: string;
  title: string;
  header: string;
  overview: React.ReactNode;
  icon: string;
  heroImage: string;
  helmet?: {
    title?: string;
    description?: string;
    noindex?: boolean;
    shareImage?: {
      url: string;
      width: string | number;
      height: string | number;
    };
    siteUrl?: string;
  };
  relatedItems: IndustryOrDataType[];
}

const IndustryDataTypeTemplate: React.FC<IndustryDataTypeTemplateProps> = (props) => {
  const { pageName, title, header, overview, icon, heroImage, helmet, relatedItems = [], children } = props;

  return (
    <IndexLayout pageName={pageName} helmet={helmet}>
      <Header variant="transparent" />
      <Hero backgroundImage={heroImage} header={header} title={title} icon={icon} overview={overview} />
      <Related items={relatedItems} />
      {children}
      <LearnMore />
      <Footer variant="light" />
    </IndexLayout>
  );
};

export default IndustryDataTypeTemplate;
