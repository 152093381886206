import './Hero.scss';
import * as React from 'react';
import Container from '../../Container';
import Typography from '../../Typography';

type Props = {
  header: string;
  title: React.ReactNode;
  overview: React.ReactNode;
  backgroundImage?: string;
  icon: string;
};

const Hero: React.FC<Props> = (props) => {
  const { title, header, overview, backgroundImage, icon } = props;

  return (
    <div className="TDB-DataTypes__HeroWrapper">
      <div className="TDB-DataTypes__Hero" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className="TDB-DataTypes__Hero__gradient" />
        <Container className="TDB-DataTypes__Hero__container">
          <Typography
            fontWeight="bold"
            fontSize="subheading-3"
            className="TDB-DataTypes__Hero__header"
            align="center"
            color="neutral-main"
            as="h2"
          >
            {header}
          </Typography>
          <Typography className="TDB-DataTypes__Hero__title" align="center" as="h3" fontSize="display-2" color="neutral-main">
            {title}
          </Typography>
        </Container>
      </div>

      <Container>
        <div className="TDB-DataTypes__Overview">
          <div className="TDB-DataTypes__Overview__Icon">{React.createElement(icon)}</div>
          <Typography fontWeight="medium" fontSize="body-lg">
            {overview}
          </Typography>
        </div>
      </Container>
    </div>
  );
};

export default Hero;
