import './RelatedItem.scss';
import React from 'react';
import { Link } from 'gatsby';
import Typography from '../../../Typography';
import arrowIcon from './assets/arrow.svg';
import placeholder from './assets/placeholder.png';

export type RelatedItemType = {
  title: string;
  link: string;
  icon: any;
};
interface RelatedItemProps {
  item: RelatedItemType;
}

const RelatedItem: React.FC<RelatedItemProps> = (props) => {
  const { item } = props;

  if (!item) {
    // fix SSR error
    return null;
  }

  return (
    <Link to={item.link} className="TDB-DataTypes__RelatedItem">
      <div className="TDB-DataTypes__RelatedItem__Icon">{React.createElement(item.icon)}</div>
      <div className="TDB-DataTypes__RelatedItem__Text">
        <Typography as="p" fontSize="subheading-1" fontWeight="semi-bold" color="neutral-900">
          {item.title}
        </Typography>
        <button className="TDB-DataTypes__RelatedItem__LearnMore">
          <Typography fontWeight="semi-bold">Learn more</Typography>
          <img src={arrowIcon} alt="" />
        </button>
      </div>
    </Link>
  );
};

export const RelatedItemPlaceholder: React.FC = () => {
  return (
    <div className="TDB-DataTypes__RelatedItemPlaceholder">
      <img src={placeholder} alt="" />
    </div>
  );
};

export default RelatedItem;
